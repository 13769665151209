import { ModuleWithProviders, NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { DeliveryRoutingModule } from './delivery-routing.module';
import { DeliveriesComponent } from './delivery-list/deliveries.component';
import { DeliveryService } from './services/delivery.service';
import { MultiselectControlModule } from '@mt-ng2/multiselect-control';
import { DeliveryBasicInfoComponent } from './delivery-basic-info/delivery-basic-info.component';
import { DeliveryHeaderComponent } from './delivery-header/delivery-header.component';
import { DeliveryDetailComponent } from './delivery-detail/delivery-detail.component';
import { DeliveryDocumentsComponent } from './delivery-documents-list/delivery-documents.component';
import { ConfirmDeliveriesDialogComponent } from './confirm-deliveries-dialog/confirm-deliveries-dialog.component';
import { ArchiveDeliveryListComponent } from './delivery-list/archive-delivery-list/archive-delivery-list.component';
import { DeliveryCalendarComponent } from './delivery-calendar/delivery-calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DeliveryCalendarTableDialogComponent } from './delivery-calendar-table-dialog/delivery-calendar-table-dialog.component';
import { DeliveryPrePaymentComponent } from './delivery-pre-payment/delivery-pre-payment.component';

@NgModule({
    declarations: [
        DeliveriesComponent,
        DeliveryBasicInfoComponent,
        DeliveryHeaderComponent,
        DeliveryDetailComponent,
        DeliveryDocumentsComponent,
        DeliveryPrePaymentComponent,
        ConfirmDeliveriesDialogComponent,
        DeliveryCalendarComponent,
        DeliveryCalendarTableDialogComponent,
        ArchiveDeliveryListComponent
    ],
    imports: [
        SharedModule, 
        DeliveryRoutingModule, 
        MultiselectControlModule,
        FullCalendarModule,
    ],
})
export class DeliveryModule {
    static forRoot(): ModuleWithProviders<DeliveryModule> {
        return {
            ngModule: DeliveryModule,
            providers: [DeliveryService]
        };
    }
}
