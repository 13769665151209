import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PublicComponent } from './public-component/public.component';
import { CustomerScheduleOrderComponent } from './customer-schedule-order/customer-schedule-order.component';
import { SelectOrderComponent } from './customer-schedule-order/select-order/select-order-component';
import { SelectTimeSlotComponent } from './customer-schedule-order/select-timeslot/select-timeslot.component';
import { EnterInformationComponent } from './customer-schedule-order/enter-information/enter-information.component';
import { PhoneNumberInputComponent } from './public-check-in/phone-number-input/phone-number-input.component';
import { DriverInfoInputComponent } from './public-check-in/driver-info-input/driver-info-input.component';
import { CarrierInputComponent } from './public-check-in/carrier-input/carrier-input.component';
import { OrderInputComponent } from './public-check-in/order-input/order-input.component';
import { PublicCheckInSuccessComponent } from './public-check-in/public-check-in-success/public-check-in-success.component';
import { ContainerInputComponent } from './public-check-in/container-input/container-input.component';
import { PublicSiteComponent } from './public-site.component';
import { PublicCheckInGuard } from './public-check-in/public-check-in-guard';
import { SelectCarrierComponent } from './customer-schedule-order/select-carrier/select-carrier-component';
import { EnterOrderComponent } from './customer-schedule-order/enter-order/enter-order-component';
import { RegistrationComponent } from './registration/registration.component';
import { GuestGuard } from './guards/guest.guard';
import { DriverCheckInComponent } from './driver-check-in/driver-check-in.component';
import { DriverDeliveringComponent } from './driver-delivering/driver-delivering.component';
import { DriverCheckedInComponent } from './driver-checked-in/driver-checked-in.component';
import { DriverNotifiedComponent } from './driver-notified/driver-notified.component';
import { DriverGatePassCodeComponent } from './driver-gate-pass-code/driver-gate-pass-code.component';
import { DriverCheckInInfoComponent } from './driver-check-in-info/driver-check-in-info.component';
import { DriverCheckInNewComponent } from './driver-check-in-new/driver-check-in-new.component';
import { DriverCheckInEditComponent } from './driver-check-in-edit/driver-check-in-edit.component';
import { DriverCheckInEditTwicCardComponent } from './driver-check-in-edit-twic-card/driver-check-in-edit-twic-card.component';
import { DriverCheckInAddPickupsComponent } from './driver-check-in-add-pickups/driver-check-in-add-pickups.component';
import { DriverCheckInPickupPaymentComponent } from './driver-check-in-pickup-payment/driver-check-in-pickup-payment.component';
import { DriverCheckInDeliveryPaymentComponent } from './driver-check-in-delivery-payment/driver-check-in-delivery-payment.component';
import { DriverCheckInSupportComponent } from './driver-check-in-support/driver-check-in-support.component';
import { DriverOrdersReadyComponent } from './driver-orders-ready/driver-orders-ready.component';
import { DriverCheckInEditPickupComponent } from './driver-check-in-edit-pickup/driver-check-in-edit-pickup.component';
import { DriverCheckInEditPickupOrdersComponent } from './driver-check-in-edit-pickup-orders/driver-check-in-edit-pickup-orders.component';
import { DriverCheckInCreatePickupComponent } from './driver-check-in-create-pickup/driver-check-in-create-pickup.component';
import { DriverCheckInCreateWalkInComponent } from './driver-check-in-create-walk-in/driver-check-in-create-walk-in.component';
import { DriverOrderConfirmationComponent } from './driver-order-confirmation/driver-order-confirmation.component';

const publicRoutes: Routes = [
    {
        children: [
            {
                component: PublicSiteComponent,
                path: '',
            },
            {
                children: [
                    {
                        component: SelectOrderComponent,
                        path: '',
                    },
                    {
                        component: SelectTimeSlotComponent,
                        path: 'time-slots',
                    },
                    {
                        component: EnterInformationComponent,
                        path: 'information',
                    },
                    {
                        component: SelectCarrierComponent,
                        path: 'select-carrier',
                    },
                    {
                        component: EnterOrderComponent,
                        path: 'enter-order',
                    },
                ],
                component: CustomerScheduleOrderComponent,
                path: 'customer-schedule-order',
            },
            {
                component: RegistrationComponent,
                path: 'registration',
                canActivate: [GuestGuard]
            },
            {
                canActivate: [PublicCheckInGuard],
                component: PhoneNumberInputComponent,
                path: 'check-in',
            },
            {
                component: DriverCheckInComponent,
                path: 'driver-check-in',
            },
            {
                component: DriverCheckedInComponent,
                path: 'driver-checked-in',
            },
            {
                component: DriverNotifiedComponent,
                path: 'driver-notified',
            },
            {
                component: DriverNotifiedComponent,
                path: `driver/:driverId/pickup/:pickupId/notified`,
            },
            {
                component: DriverGatePassCodeComponent,
                path: `driver/:driverId/pickup/:pickupId/gate-pass-barcode`,
            },
            {
                component: DriverCheckInInfoComponent,
                path: 'driver-check-in-info',
            },
            {
                component: DriverCheckInNewComponent,
                path: 'driver-check-in-new',
            },
            {
                component: DriverCheckInEditComponent,
                path: `driver-check-in-edit`,
            },
            {
                component: DriverCheckInDeliveryPaymentComponent,
                path: 'driver-check-in-delivery-payment',
            },
            {
                component: DriverCheckInPickupPaymentComponent,
                path: 'driver-check-in-pickup-payment',
            },
            {
                component: DriverCheckInEditTwicCardComponent,
                path: 'driver-check-in-edit-twic-card',
            },
            {
                component: DriverCheckInAddPickupsComponent,
                path: 'driver-check-in-add-pickups',
            },
            {
                component: DriverCheckInSupportComponent,
                path: 'driver-check-in-support',
            },
            {
                component: DriverOrdersReadyComponent,
                path: 'driver-orders-ready',
            },
            {
                component: DriverOrderConfirmationComponent,
                path: 'driver-order-confirmation',
            },
            {
                component: DriverCheckInEditPickupComponent,
                path: 'driver-check-in-edit-pickup',
            },
            {
                component: DriverCheckInEditPickupOrdersComponent,
                path: 'driver-check-in-edit-pickup-orders',
            },
            {
                component: DriverCheckInCreatePickupComponent,
                path: 'driver-check-in-create-pickup',
            },
            {
                component: DriverCheckInCreateWalkInComponent,
                path: 'driver-check-in-create-walk-in',
            },
            {
                component: DriverDeliveringComponent,
                path: 'driver-delivering',
            },
            {
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'driver',
                    },
                    {
                        canActivate: [PublicCheckInGuard],
                        component: DriverInfoInputComponent,
                        path: 'driver',
                    },
                    {
                        canActivate: [PublicCheckInGuard],
                        component: CarrierInputComponent,
                        path: 'carrier',
                    },
                    {
                        canActivate: [PublicCheckInGuard],
                        component: OrderInputComponent,
                        path: 'orders',
                    },
                    {
                        canActivate: [PublicCheckInGuard],
                        component: PublicCheckInSuccessComponent,
                        path: 'success',
                    },
                ],
                path: 'pick-up',
            },
            {
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'driver',
                    },
                    {
                        canActivate: [PublicCheckInGuard],
                        component: DriverInfoInputComponent,
                        path: 'driver',
                    },
                    {
                        canActivate: [PublicCheckInGuard],
                        component: ContainerInputComponent,
                        path: 'container',
                    },
                    {
                        canActivate: [PublicCheckInGuard],
                        component: PublicCheckInSuccessComponent,
                        path: 'success',
                    },
                ],
                path: 'delivery',
            },
        ],
        component: PublicComponent,
        data: { isPublic: true },
        path: 'public',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(publicRoutes)],
})
export class PublicRoutingModule {}
