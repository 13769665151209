import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../common/shared.module';
import { PublicComponent } from './public-component/public.component';
import { PublicRoutingModule } from './public-routing.module';
import { CustomerScheduleOrderComponent } from './customer-schedule-order/customer-schedule-order.component';
import { SelectTimeSlotComponent } from './customer-schedule-order/select-timeslot/select-timeslot.component';
import { SelectOrderComponent } from './customer-schedule-order/select-order/select-order-component';
import { EnterInformationComponent } from './customer-schedule-order/enter-information/enter-information.component';
import { CustomerScheduleOrderService } from './customer-schedule-order/customer-schedule-order.service';
import { NgxLoadingModule } from 'ngx-loading';
import { CarrierInputComponent } from './public-check-in/carrier-input/carrier-input.component';
import { ContainerInputComponent } from './public-check-in/container-input/container-input.component';
import { DriverInfoInputComponent } from './public-check-in/driver-info-input/driver-info-input.component';
import { OrderInputComponent } from './public-check-in/order-input/order-input.component';
import { PhoneNumberInputComponent } from './public-check-in/phone-number-input/phone-number-input.component';
import { PublicCheckInSuccessComponent } from './public-check-in/public-check-in-success/public-check-in-success.component';
import { PublicCheckInService } from './public-check-in/services/public-check-in.service';
import { PublicSiteComponent } from './public-site.component';
import { SelectCarrierComponent } from './customer-schedule-order/select-carrier/select-carrier-component';
import { EnterOrderComponent } from './customer-schedule-order/enter-order/enter-order-component';
import { RegistrationComponent } from './registration/registration.component';
import { RegistrationService } from './registration/services/registration.service';
import { DriverCheckInComponent } from './driver-check-in/driver-check-in.component';
import { DriverDeliveringComponent } from './driver-delivering/driver-delivering.component';
import { DriverCheckedInComponent } from './driver-checked-in/driver-checked-in.component';
import { DriverNotifiedComponent } from './driver-notified/driver-notified.component';
import { DriverGatePassCodeComponent } from './driver-gate-pass-code/driver-gate-pass-code.component';
import { DriverCheckInInfoComponent } from './driver-check-in-info/driver-check-in-info.component';
import { DriverCheckInNewComponent } from './driver-check-in-new/driver-check-in-new.component';
import { DriverCheckInEditComponent } from './driver-check-in-edit/driver-check-in-edit.component';
import { DriverCheckInEditTwicCardComponent } from './driver-check-in-edit-twic-card/driver-check-in-edit-twic-card.component';
import { DriverCheckInAddPickupsComponent } from './driver-check-in-add-pickups/driver-check-in-add-pickups.component';
import { DriverCheckInPickupPaymentComponent } from './driver-check-in-pickup-payment/driver-check-in-pickup-payment.component';
import { DriverCheckInDeliveryPaymentComponent } from './driver-check-in-delivery-payment/driver-check-in-delivery-payment.component';
import { DriverCheckInSupportComponent } from './driver-check-in-support/driver-check-in-support.component';
import { DriverCheckInSupportButtonComponent } from './driver-check-in-support-button/driver-check-in-support-button.component';
import { DriverOrdersReadyComponent } from './driver-orders-ready/driver-orders-ready.component';
import { DriverCheckInEditPickupComponent } from './driver-check-in-edit-pickup/driver-check-in-edit-pickup.component';
import { DriverCheckInEditPickupOrdersComponent } from './driver-check-in-edit-pickup-orders/driver-check-in-edit-pickup-orders.component';
import { DriverCheckInCreatePickupComponent } from './driver-check-in-create-pickup/driver-check-in-create-pickup.component';
import { DriverCheckInCreateWalkInComponent } from './driver-check-in-create-walk-in/driver-check-in-create-walk-in.component';
import { DriverOrderConfirmationComponent } from './driver-order-confirmation/driver-order-confirmation.component';
import { MtPayPalModule, IMtPayPalModuleConfig, MtPayPalModuleConfigToken } from '@mt-ng2/paypal';
import { environment } from '../environments/environment';

const paypalConfig: IMtPayPalModuleConfig = {
    clientId: environment.payPalClientId 
}

@NgModule({
    declarations: [
        PublicComponent,
        CustomerScheduleOrderComponent,
        SelectTimeSlotComponent,
        SelectOrderComponent,
        SelectCarrierComponent,
        EnterOrderComponent,
        EnterInformationComponent,
        CarrierInputComponent,
        ContainerInputComponent,
        DriverInfoInputComponent,
        OrderInputComponent,
        PhoneNumberInputComponent,
        PublicCheckInSuccessComponent,
        PublicSiteComponent,
        RegistrationComponent,
        DriverCheckInComponent,
        DriverDeliveringComponent,
        DriverCheckedInComponent,
        DriverNotifiedComponent,
        DriverGatePassCodeComponent,
        DriverCheckInInfoComponent,
        DriverCheckInNewComponent,
        DriverCheckInEditComponent,
        DriverCheckInEditTwicCardComponent,
        DriverCheckInAddPickupsComponent,
        DriverCheckInPickupPaymentComponent,
        DriverCheckInDeliveryPaymentComponent,
        DriverCheckInSupportComponent,
        DriverCheckInSupportButtonComponent,
        DriverOrdersReadyComponent,
        DriverCheckInEditPickupComponent,
        DriverCheckInEditPickupOrdersComponent,
        DriverCheckInCreatePickupComponent,
        DriverCheckInCreateWalkInComponent,
        DriverOrderConfirmationComponent,
    ],
    imports: [SharedModule, PublicRoutingModule, NgxLoadingModule, ReactiveFormsModule, MtPayPalModule],
    providers: [
        CustomerScheduleOrderService, 
        PublicCheckInService, 
        RegistrationService,
        { provide: MtPayPalModuleConfigToken, useValue: paypalConfig },
    ],
})
export class PublicModule {
    static forRoot(): ModuleWithProviders<PublicModule> {
        return {
            ngModule: PublicModule,
        };
    }
}
