import { AfterViewInit, Directive, ElementRef, ViewChild } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ApplePay, Card, GooglePay } from '@square/web-sdk';
import { SquarePaymentService } from '../../common/services/square-payment-service';

@Directive()
export abstract class BasePaymentComponent implements AfterViewInit {
    @ViewChild('creditCardContainer') creditCardContainer: ElementRef<HTMLDivElement>;
    @ViewChild('applePayContainer') applePayContainer: ElementRef<HTMLDivElement>;
    @ViewChild('creditCardButton') creditCardButton: ElementRef<HTMLButtonElement>;
    @ViewChild('applePayButton') applePayButton: ElementRef<HTMLButtonElement>;
    @ViewChild('googlePayButton') googlePayButton: ElementRef<HTMLDivElement>;

    selectedPaymentMethod = 'creditCard';
    loading = true;
    applePayNotLoaded = false;
    redirectInProcess = false;

    protected abstract squareLocationId: string;
    protected abstract remainingBalance: number;

    constructor(
        protected squarePaymentService: SquarePaymentService,
        protected notificationsService: NotificationsService
    ) {}

    async ngAfterViewInit() {
        await this.initializePayment();
    }

    protected abstract handleSuccessfulPayment(token: string): void;

    async initializePayment(): Promise<void> {
        await new Promise(resolve => setTimeout(resolve, 2500));
        if (this.redirectInProcess || !this.squareLocationId) {
            this.loading = false;
            return;
        }

        await this.squarePaymentService.loadSquareScript();
        await this.initCreditCardPayment();
    }

    async initCreditCardPayment(): Promise<void> {
        this.loading = true;
        const payments = await this.squarePaymentService.initializePayments(this.squareLocationId);
        
        const creditCard = await this.squarePaymentService.initializeCreditCard(
            payments, 
            this.creditCardContainer.nativeElement
        );

        this.loading = false;
        if (!creditCard) return;

        this.creditCardButton.nativeElement.addEventListener('click', () => {
            void this.processPayment(creditCard);
        });
    }

    async initApplePayPayment(): Promise<void> {
        this.loading = true;
        const payments = await this.squarePaymentService.initializePayments(this.squareLocationId);
        
        const applePay = await this.squarePaymentService.initializeApplePay(
            payments,
            this.remainingBalance.toString()
        );

        this.loading = false;
        if (!applePay) {
            this.applePayNotLoaded = true;
            return;
        }

        this.applePayButton.nativeElement.addEventListener('click', () => {
            void this.processPayment(applePay);
        });
    }

    async initGooglePayPayment(): Promise<void> {
        this.loading = true;
        const payments = await this.squarePaymentService.initializePayments(this.squareLocationId);
        
        const googlePay = await this.squarePaymentService.initializeGooglePay(
            payments,
            this.remainingBalance.toFixed(2),
            this.googlePayButton.nativeElement
        );

        this.loading = false;
        if (!googlePay) return;

        this.googlePayButton.nativeElement.addEventListener('click', () => {
            void this.processPayment(googlePay);
        });
    }

    async processPayment(payment: Card | ApplePay | GooglePay): Promise<void> {
        this.loading = true;
        const token = await this.squarePaymentService.handlePayment(payment);
        
        if (token) {
            this.handleSuccessfulPayment(token);
        }
    }

    onPaymentMethodChange(method: string): void {
        this.selectedPaymentMethod = method;
        switch (method) {
            case 'creditCard':
                void this.initCreditCardPayment();
                break;
            case 'applePay':
                void this.initApplePayPayment();
                break;
            case 'googlePay':
                void this.initGooglePayPayment();
                break;
        }
    }

    determineApplePayButtonText(): string {
        if (this.applePayNotLoaded) {
            return 'Apple Pay is not available';
        }
        return this.loading ? 'Loading...' : 'Pay';
    }
}