import { Injectable } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ApplePay, Card, GooglePay, Payments, Square } from '@square/web-sdk';
import { environment } from '../../environments/environment';
import { ScriptLoaderService } from '../../common/services/script-loader-service';

declare global {
    interface Window {
        Square?: Square;
    }
}

@Injectable({
    providedIn: 'root'
})
export class SquarePaymentService {
    constructor(
        private notificationsService: NotificationsService,
        private scriptLoader: ScriptLoaderService
    ) { }

    async loadSquareScript(): Promise<void> {
        try {
            await this.scriptLoader.loadScript(environment.squareWebPaymentsScriptUrl);
        } catch (error) {
            this.notificationsService.error('There was an error loading the Square payment script.');
            throw error;
        }
    }

    async initializePayments(squareLocationId: string): Promise<Payments> {
        await new Promise(resolve => setTimeout(resolve, 2500));
        return window.Square?.payments(environment.squareApplicationId, squareLocationId);
    }

    async initializeCreditCard(payments: Payments, container: HTMLDivElement): Promise<Card | null> {
        try {
            const card = await payments.card();
            await card.attach(container);
            return card;
        } catch (error) {
            this.notificationsService.error('There was an error loading the credit card payment script.');
            return null;
        }
    }

    async initializeApplePay(payments: Payments, amount: string): Promise<ApplePay | null> {
        const paymentRequest = payments.paymentRequest({
            countryCode: 'US',
            currencyCode: 'USD',
            total: {
                amount: amount,
                label: 'Total',
            },
        });

        try {
            return await payments.applePay(paymentRequest);
        } catch (error) {
            this.notificationsService.error('There was an error loading the Apple Pay payment script.');
            // eslint-disable-next-line no-console
            console.error(error as string);
            return null;
        }
    }

    async initializeGooglePay(payments: Payments, amount: string, container: HTMLDivElement): Promise<GooglePay | null> {
        const paymentRequest = payments.paymentRequest({
            countryCode: 'US',
            currencyCode: 'USD',
            total: {
                amount: amount,
                label: 'Total',
            },
        });

        try {
            const googlePay = await payments.googlePay(paymentRequest);
            await googlePay.attach(container);
            return googlePay;
        } catch (error) {
            this.notificationsService.error('There was an error loading the Google Pay payment script.');
            // eslint-disable-next-line no-console
            console.error(error as string);
            return null;
        }
    }

    async handlePayment(payment: Card | ApplePay | GooglePay): Promise<string | null> {
        try {
            const result = await payment.tokenize();
            const status = result.status.toString();
            
            if (status === 'OK') {
                return result.token;
            }
            
            if (status === 'Cancel') {
                this.notificationsService.info('The payment was canceled.');
                return null;
            }

            let errorMessage = `Tokenization failed with status: ${result.status}`;
            if (result.errors) {
                errorMessage += ` and errors: ${JSON.stringify(result.errors)}`;
            }
            this.notificationsService.error(errorMessage);
            return null;
        } catch (error) {
            this.notificationsService.error('There was an error processing the payment.');
            return null;
        }
    }
}