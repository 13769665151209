import { Component } from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';
import { OrderService } from '../../orders/order.service';
import { PickupService } from '../../model/shared-entities/pickups/pickup.service';
import { IPickup } from '../../model/interfaces/pickup';
import { UserRoles } from '../../model/UserRoles';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { BasePaymentComponent } from '../../common/components/base-payment.component';
import { SquarePaymentService } from '../../common/services/square-payment-service';

@Component({
    selector: 'app-pickup-pre-payment',
    templateUrl: './pickup-pre-payment.component.html',
    styleUrls: ['./pickup-pre-payment.component.css'],
})
export class PickupPrePaymentComponent extends BasePaymentComponent {
    pickup: IPickup;
    pickupId: number;
    twicFeeLabel = 'TWIC Fee';
    protected squareLocationId: string;
    protected remainingBalance: number;

    constructor(
        private pickupService: PickupService,
        private orderService: OrderService,
        private authService: AuthService,
        private route: ActivatedRoute,
        protected override squarePaymentService: SquarePaymentService,
        protected override notificationsService: NotificationsService,
        private router: Router,
    ) {
        super(squarePaymentService, notificationsService);
    }

    ngOnInit(): void {
        this.pickupId = +this.route.snapshot.paramMap.get('pickupId');
        const currentUser = this.authService.currentUser.getValue();
        this.orderService.getAuthUserRole(currentUser.AuthId).subscribe(authUserRole => {
            if (authUserRole !== UserRoles.Carrier) {
                this.redirectInProcess = true;
                void this.router.navigate(['/create-requests/manage-pickups']);
                return;
            }
        });

        this.pickupService.getById(this.pickupId).subscribe({
            next: (pickup) => {
                if (!pickup) {
                    this.redirectInProcess = true;
                    void this.router.navigate(['/create-requests/manage-pickups']);
                    return;
                }

                this.pickup = pickup;
                this.pickupService.selectedPickup$.next(pickup);
            }
        });

        this.pickupService.getRemainingBalance(this.pickupId).subscribe({
            next: (remainingBalance) => {
                if (this.remainingBalance <= 0) {
                    this.redirectInProcess = true;
                    this.notificationsService.error('The remaining balance for this pickup is $0.00. No payment is required.');
                    void this.router.navigate(['/create-requests/manage-pickups']);
                    return;
                }

                this.remainingBalance = remainingBalance;
            }
        });

        this.pickupService.getSquareLocationId(this.pickupId).subscribe({
            next: (squareLocationId) => {
                this.squareLocationId = squareLocationId;
                if (!this.squareLocationId) {
                    this.redirectInProcess = true;
                    this.notificationsService.error('The selected pickup does not have a office with a Square Location ID. Please contact support.');
                    void this.router.navigate(['/create-requests/manage-pickups']);
                    return;
                }
            }
        });

        this.pickupService.isOutsidePrePaymentTimeframe(this.pickupId).subscribe({
            next: (isOutsidePrePaymentTimeframe) => {
                if (isOutsidePrePaymentTimeframe) {
                    this.redirectInProcess = true;
                    this.notificationsService.error('The ability to pre-pay for this pickup is outside the pre-payment time frame.');
                    void this.router.navigate(['/create-requests/manage-pickups']);
                    return;
                }
            }
        });
    }

    protected handleSuccessfulPayment(token: string): void {
        this.pickupService.captureSquareOrder(this.pickup.Id, token).subscribe({
            next: () => this.orderPaid(),
            error: () => {
                this.notificationsService.error('There was an error capturing the payment.');
                this.loading = false;
            }
        });
    }

    orderPaid(): void {
        this.notificationsService.success(`Your payment has been successfully processed in the amount of $${this.remainingBalance.toFixed(2)} for pickup ${this.pickup.PickupNumber}.`);
        void this.router.navigate(['/create-requests/manage-pickups']);            
    }
}
