import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CheckInType } from '../../model/CheckInType';
import { CheckInService } from '../../check-ins/services/check-ins.service';
import { CommonService } from '../../common/services/common.service';
import { IUser } from '../../model/interfaces/user';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IDelivery } from '../../model/interfaces/delivery';
import { PublicCheckInDTO } from '../../model/interfaces/custom/public-check-in-dto';
import { IOrderPartial } from '../../model/partials/order-partial';
import { ICheckIn } from '../../model/interfaces/check-in';

@Component({
    selector: 'app-driver-delivering',
    templateUrl: './driver-delivering.component.html',
    styleUrls: ['./driver-delivering.component.css'],
})
export class DriverDeliveringComponent implements OnInit {
    driver: IUser;
    editDriverForm: FormGroup;
    delivery: IDelivery;
    checkIn: ICheckIn;
    checkedIn = false;
    doubleClickIsDisabled = false;

    constructor(
        private router: Router, 
        private publicCheckInService: PublicCheckInService,
        private checkInService: CheckInService,
        private formBuilder: FormBuilder, 
        private commonService: CommonService,
        private notificationsService: NotificationsService
    ) { }

    ngOnInit(): void {
        this.publicCheckInService.checkedInDriver$.subscribe((driver) => {
            this.driver = driver;
            if (!driver) {
                void this.router.navigate(['public/driver-check-in-info']);
                return;
            }

            this.publicCheckInService.driverCreatedCheckIn$.subscribe((checkIn) => {
                this.checkIn = checkIn;
                if (this.checkIn?.Id > 0) {
                    this.checkDeliveryBalance(this.checkIn.Id);
                    return;
                }
                this.editDriverForm = this.formBuilder.group({
                    firstName: [this.driver.FirstName, [Validators.required, this.commonService.firstAndLastNameValidator()]],
                    lastName: [this.driver.LastName, [Validators.required, this.commonService.firstAndLastNameValidator()]],
                    licensePlateNumber: ['', [Validators.required, this.commonService.checkForAlphaNumericCharacters()]],
                    driverLicenseNumber: [''],
                    containerNumber: ['', [Validators.required, this.commonService.checkForAlphaNumericCharacters()]],
                });
            });
        });
    }

    onSubmit(): void {
        const orderNumber = this.editDriverForm.get('containerNumber')?.value as string;
        this.publicCheckInService.getValidDeliveryByContainerNumber(orderNumber).subscribe({
            next: (delivery: IDelivery) => this.handleDeliveryResponse(delivery, orderNumber),
            error: (error: HttpErrorResponse) => this.commonService.handleErrorResponse(error),
        });
    }

    private handleDeliveryResponse(delivery: IDelivery, orderNumber: string): void {
        this.delivery = delivery;
        if (!this.delivery) {
            this.notificationsService.error('Invalid Container # / Trailer # number. Please try again.');
            return;
        }

        this.checkIn = delivery.CheckIns.find(
            (checkIn) => checkIn.CheckInUserId === this.driver.Id
        );

        if (this.checkIn?.Id > 0) {
            this.checkDeliveryBalance(this.checkIn.Id);
            return;
        }

        const order = this.createOrder(orderNumber);
        const publicCheckInDTO = this.createPublicCheckInDTO(delivery, order);

        this.publicCheckInService.submitPublicCheckIn(publicCheckInDTO).subscribe({
            next: (checkInId: number) => this.handleCheckInSubmission(checkInId),
            error: (error: HttpErrorResponse) => this.commonService.handleErrorResponse(error),
        });
    }

    private handleCheckInSubmission(checkInId: number): void {
        this.publicCheckInService.sendCheckInNotifications(checkInId).subscribe({
            next: (notified: boolean) => this.handleNotificationResponse(checkInId, notified),
            error: (error: HttpErrorResponse) => this.commonService.handleErrorResponse(error),
        });
    }

    private handleNotificationResponse(checkInId: number, notified: boolean): void {
        const checkInLog = {
            CheckInId: checkInId,
            DateCreatedUtc: new Date(),
            Id: 0,
            LogMessage: '[PUBLIC DELIVERY CHECK-IN] driver successfully checked in, but notifications failed to send.',
        };

        if (notified) {
            checkInLog.LogMessage = '[PUBLIC DELIVERY CHECK-IN] driver successfully checked in. Notifications sent successfully.';
        }

        this.checkInService.createCheckInLog(checkInLog).subscribe({
            next: (checkIn) => {
                this.checkIn = checkIn;
                this.checkDeliveryBalance(checkIn.Id);
            },
            error: (error: HttpErrorResponse) => this.commonService.handleErrorResponse(error),
        });
    }

    private checkDeliveryBalance(checkInId: number): void {
        this.publicCheckInService.getRemainingBalanceForDelivery(checkInId).subscribe({
            next: (remainingBalance: number) => {
                if (remainingBalance > 0) {
                    this.delivery.CheckIns = [this.checkIn];
                    this.publicCheckInService.checkedInDelivery$.next(this.delivery);
                    void this.router.navigate(['public/driver-check-in-delivery-payment']);
                } else {
                    this.checkedIn = true;
                }
            },
            error: (error: HttpErrorResponse) => this.commonService.handleErrorResponse(error),
        });
    }
    
    private createPublicCheckInDTO(delivery: IDelivery, order: IOrderPartial): PublicCheckInDTO {
        return {
            firstName: this.editDriverForm.get('firstName')?.value,
            lastName: this.editDriverForm.get('lastName')?.value,
            licensePlate: this.editDriverForm.get('licensePlateNumber')?.value,
            driversLicense: this.editDriverForm.get('driverLicenseNumber')?.value,
            carrierId: this.driver.CarrierId,
            phone: this.driver.Phone,
            checkInTypeId: CheckInType.PICKUP,
            validOrders: [order],
            deliveryId: delivery.Id,
            driverId: this.driver.Id,
        };
    }
    
    private createOrder(orderNumber: string): IOrderPartial {
        const order = this.publicCheckInService.getEmptyOrder();
        order.OrderNumber = orderNumber;
        return order;
    }
}
