import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from '../../model/interfaces/user';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';
import { ICheckIn } from '../../model/interfaces/check-in';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Location } from '@angular/common';
import { IDelivery } from '../../model/interfaces/delivery';
import { CheckInPayPalService } from '../driver-check-in-pickup-payment/services/check-in-paypal.service';
import { BasePaymentComponent } from '../../common/components/base-payment.component';
import { SquarePaymentService } from '../../common/services/square-payment-service';

@Component({
    selector: 'app-driver-check-in-delivery-payment',
    templateUrl: './driver-check-in-delivery-payment.component.html',
    styleUrls: ['./driver-check-in-delivery-payment.component.css'],
})
export class DriverCheckInDeliveryPaymentComponent extends BasePaymentComponent {
    driver: IUser;
    delivery: IDelivery;
    checkIn: ICheckIn;
    twicFeeLabel = 'TWIC Fee';
    protected squareLocationId: string;
    protected remainingBalance: number;

    constructor(
        private location: Location,
        private router: Router,
        private checkInService: PublicCheckInService,
        public checkInPayPalService: CheckInPayPalService,
        protected override squarePaymentService: SquarePaymentService,
        protected override notificationsService: NotificationsService,
    ) {
        super(squarePaymentService, notificationsService);
    }

    ngOnInit(): void {
        this.checkInService.checkedInDriver$.subscribe((driver) => {
            this.driver = driver;
            if (!driver) {
                this.redirectInProcess = true;
                void this.router.navigate(['public/driver-check-in-info']);
                return;
            }

            const twicCardIsCaptured = this.driver.TwicBackImageId && this.driver.TwicFrontImageId;
            const twicCardExpired = new Date(this.driver.TwicExpirationDate) < new Date();
            if (!twicCardIsCaptured || twicCardExpired) {
                this.twicFeeLabel = 'Non-TWIC Fee';
            }

            this.checkInService.checkedInDelivery$.subscribe((delivery) => {
                this.delivery = delivery;
                if (!delivery) {
                    this.redirectInProcess = true;
                    void this.router.navigate(['public/driver-check-in-info']);
                    return;
                }

                this.checkIn = delivery.CheckIns.find(c => c.CheckInUserId === this.driver.Id); 
                if (!this.checkIn) {
                    this.redirectInProcess = true;
                    void this.router.navigate(['public/driver-check-in-info']);
                    return;
                }

                this.checkInService.getRemainingBalanceForDelivery(this.checkIn.Id).subscribe({
                    next: (remainingBalance) => {
                        this.remainingBalance = remainingBalance;
                        if (this.remainingBalance <= 0) {
                            this.redirectInProcess = true;
                            void this.router.navigate(['public/driver-checked-in']);
                            return;
                        }
                    }
                });

                this.checkInService.getSquareLocationIdForDelivery(this.delivery.Id).subscribe({
                    next: (squareLocationId) => {
                        this.squareLocationId = squareLocationId;
                    }
                });
            });
        });
    }

    protected handleSuccessfulPayment(token: string): void {
        this.checkInService.captureSquareOrderForDelivery(this.checkIn.Id, token).subscribe({
            next: () => this.orderPaid(),
            error: () => {
                this.notificationsService.error('There was an error capturing the payment.');
                this.loading = false;
            }
        });
    }

    orderPaid() {
        this.checkInPayPalService
            .sendPaymentSms(this.checkIn.Id, this.remainingBalance)
            .subscribe({
                next: () => {
                    this.notificationsService.success(`${this.driver.FirstName} ${this.driver.LastName}, your payment has been successfully processed in the amount of $${this.remainingBalance.toFixed(2)}.`);
                    this.checkInService.checkedInDriver$.next(this.driver);
                    this.checkInService.driverCreatedCheckIn$.next(this.checkIn);
                    void this.router.navigate(['public/driver-delivering']);
                },
                error: () => {
                    this.notificationsService.error('There was an error sending SMS of successful payment.');
                },
            });
    }

    goBack(): void {
        this.location.back();
    }
}
