import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICheckIn } from '../../model/interfaces/check-in';
import { IGuestUser } from '../../model/interfaces/guest-user';
import { ICheckInDetail } from '../../model/interfaces/check-in-detail';
import { CheckInSearchParams } from '../check-in-search-classes/check-in-search.class';
import { CheckInQueueItem } from '../../model/classes/check-in-queue-item';
import { map } from 'rxjs/operators';
import { ICheckInLog } from '../../model/interfaces/check-in-log';
import { ICheckInQueueItem } from '../../model/interfaces/custom/check-in-queue-item';
import { ICheckInReportView } from '../../model/interfaces/custom/check-in-report-view';
import { IUser } from '../../model/interfaces/user';
import { PickupDoorPayload } from '../../model/classes/pickup-door-payload';

export interface IToBeFulfilledParam {
    Query: string;
    Order: string;
    OrderDirection: string;
    StatusIds: number[];
    WarehouseIds: number[];
    CustomerIds: number[];
    OfficeIds: number[];
    DriverIds: number[];
}

@Injectable()
export class CheckInService {
    constructor(public http: HttpClient) {
    }

    getToBeFulFilled(param: IToBeFulfilledParam): Observable<CheckInQueueItem[]> {
        return this.http.post<ICheckInQueueItem[]>(`/checkIns/ToBeFulFilled`, param).pipe(map((answer) => answer.map((item) =>
            new CheckInQueueItem(item))));
    }

    getCheckInReport(searchParams: CheckInSearchParams): Observable<ICheckInReportView> {
        return this.http.post<ICheckInReportView>(`/checkIns/report`, searchParams);
    }

    getCheckInReportCsv(searchParams: CheckInSearchParams): Observable<string> {
        const headers = new HttpHeaders({
            Accept: 'text/csv',
        });
        return this.http.post(`/checkIns/report/csv`, searchParams, { headers: headers, responseType: 'text' });
    }
    markFulFilled(checkInId: number): Observable<object> {
        return this.http.put(`/checkIns/markFulfilled/${checkInId}`, { responseType: 'text' as 'json' });
    }
    markNotFulFilled(checkInId: number, reasonId: number): Observable<object> {
        return this.http.put(`/checkIns/markNotFulFilled/${checkInId}/reason/${reasonId}`, { responseType: 'text' as 'json' });
    }
    undoMarkingFulFilled(checkInId: number): Observable<object> {
        return this.http.put(`/checkIns/undoMarkFulfilled/${checkInId}`, { responseType: 'text' as 'json' });
    }
    markOrderFulFilled(checkInId: number, orderId: number): Observable<void> {
        return this.http.put<void>(`/checkIns/${checkInId}/order/${orderId}/markFulfilled`, { responseType: 'text' as 'json' });
    }
    markOrderNotFulFilled(checkInId: number, orderId: number, reasonId: number): Observable<void> {
        return this.http.put<void>(`/checkIns/${checkInId}/order/${orderId}/reason/${reasonId}/markNotFulfilled`, { responseType: 'text' as 'json' });
    }
    notifyDriver(checkInId: number, gatePassId: string): Observable<boolean> {
        return this.http.put<boolean>(`/checkIns/notifyDriver/${checkInId}`, { GatePassId: gatePassId }, { responseType: 'text' as 'json' });
    }
    notifyDriverWithPickUpDetails(checkInId: number, pickupDoorPayload: PickupDoorPayload[], gatePassId: string): Observable<boolean> {
        return this.http.put<boolean>(`/checkIns/notifyDriverWithPickUpDetails/`, { CheckinId: checkInId, PickupDoorPayload: pickupDoorPayload, GatePassId: gatePassId }, { responseType: 'text' as 'json' });
    }
    resendNotification(checkInDetailId: number): Observable<boolean> {
        return this.http.put<boolean>(`/checkIns/resendNotification/${checkInDetailId}`, { responseType: 'text' as 'boolean' });
    }
    deleteCheckIn(checkInId: number): Observable<object> {
        return this.http.delete(`/checkIns/delete/${checkInId}`, { responseType: 'text' as 'json' });
    }
    getById(checkInId: number): Observable<ICheckIn> {
        return this.http.get<ICheckIn>(`/checkIns/details/${checkInId}`);
    }
    createTrucker(trucker: IUser, isGuest: boolean): Observable<number> {
        if (isGuest) {
            return this.http.post<number>(`/checkIns/guest`, trucker);
        } else {
            return this.http.post<number>(`/truckers`, trucker);
        }
    }
    createCheckIn(truckerId: number, isGuest: boolean): Observable<number> {
        return this.http.get<number>(`/checkIns/create/${truckerId}/${isGuest.toString()}`);
    }

    sendNotifications(checkInId: number): Observable<boolean> {
        return this.http.get<boolean>(`/checkIns/sendNotifications/${checkInId}`);
    }
    updateTruckerGuestInfo(trucker: IGuestUser): Observable<object> {
        return this.http.put(`/checkIns/guest`, trucker);
    }
    createCheckInDetail(checkIndetail: ICheckInDetail): Observable<object> {
        return this.http.post(`/checkIns/checkIndetail`, checkIndetail);
    }
    updateCheckInDetail(checkIndetail: ICheckInDetail): Observable<object> {
        return this.http.put(`/checkIns/checkIndetail`, checkIndetail);
    }
    getCheckInDetails(checkInId: number): Observable<ICheckInDetail[]> {
        return this.http.get<ICheckInDetail[]>(`/checkIns/checkIndetails/${checkInId}`);
    }
    deleteCheckInDetail(checkInDetailId: number): Observable<object> {
        return this.http.delete(`/checkIns/checkInDetail/delete/${checkInDetailId}`, { responseType: 'text' as 'json' });
    }
    checkIsDAEOfficeUser(): Observable<boolean> {
        return this.http.get<boolean>(`/checkIns/isDAEOfficeUser`);
    }
    createCheckInLog(checkInLog: ICheckInLog): Observable<ICheckIn> {
        return this.http.post<ICheckIn>(`/checkIns/createCheckInLog`, checkInLog);
    }
    updateCheckInComment(id: number, comment: string): Observable<ICheckIn> {
        return this.http.post<ICheckIn>(`/checkIns/${id}/comment`, { comment: comment });
    }
}
