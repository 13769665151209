import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IOrder } from '../../model/interfaces/order';
import { IPickup } from '../../model/interfaces/pickup';
import { IUser } from '../../model/interfaces/user';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';
import { IDelivery } from '../../model/interfaces/delivery';

@Component({
    selector: 'app-driver-check-in-support-button',
    templateUrl: './driver-check-in-support-button.component.html',
    styleUrls: ['./driver-check-in-support-button.component.css'],
})
export class DriverCheckInSupportButtonComponent {
    @Input('driver') driver: IUser;
    @Input('pickup') pickup: IPickup;
    @Input('delivery') delivery: IDelivery;
    @Input('orders') orders: IOrder[];

    constructor(private router: Router, private checkInService: PublicCheckInService) { }

    goToDriverCheckInSupportPage(): void {
        this.checkInService.checkedInDriver$.next(this.driver);
        this.checkInService.checkedInPickup$.next(this.pickup);
        this.checkInService.checkedInDelivery$.next(this.delivery);
        this.checkInService.driverSelectedOrders$.next(this.orders);
        void this.router.navigate([`public/driver-check-in-support`]);
    }
}
