import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IPickup } from '../../model/interfaces/pickup';
import { IUser } from '../../model/interfaces/user';
import { PublicCheckInService } from '../public-check-in/services/public-check-in.service';
import { CheckInPayPalService } from './services/check-in-paypal.service';
import { ICheckIn } from '../../model/interfaces/check-in';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { BasePaymentComponent } from '../../common/components/base-payment.component';
import { SquarePaymentService } from '../../common/services/square-payment-service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-driver-check-in-pickup-payment',
    templateUrl: './driver-check-in-pickup-payment.component.html',
    styleUrls: ['./driver-check-in-pickup-payment.component.css'],
})
export class DriverCheckInPickupPaymentComponent extends BasePaymentComponent {
    driver: IUser;
    pickup: IPickup;
    checkIn: ICheckIn;
    twicFeeLabel = 'TWIC Fee';
    protected squareLocationId: string;
    protected remainingBalance: number;

    constructor(
        protected override squarePaymentService: SquarePaymentService,
        protected override notificationsService: NotificationsService,
        private checkInService: PublicCheckInService,
        private checkInPayPalService: CheckInPayPalService,
        private router: Router,
        private location: Location,
    ) {
        super(squarePaymentService, notificationsService);
    }

    ngOnInit(): void {
        this.checkInService.checkedInDriver$.subscribe((driver) => {
            this.driver = driver;
            if (!driver) {
                this.redirectInProcess = true;
                void this.router.navigate(['public/driver-check-in-info']);
                return;
            }

            const twicCardIsCaptured = this.driver.TwicBackImageId && this.driver.TwicFrontImageId;
            const twicCardExpired = new Date(this.driver.TwicExpirationDate) < new Date();
            if (!twicCardIsCaptured || twicCardExpired) {
                this.twicFeeLabel = 'Non-TWIC Fee';
            }

            this.checkInService.driverCreatedCheckIn$.subscribe((checkIn) => {
                this.checkIn = checkIn;
                if (!checkIn) {
                    this.redirectInProcess = true;
                    void this.router.navigate(['public/driver-check-in-info']);
                    return;
                }

                this.checkInService.checkedInPickup$.subscribe((pickup) => {
                    this.pickup = pickup;
                    if (!pickup) {
                        this.redirectInProcess = true;
                        void this.router.navigate(['public/driver-check-in-info']);
                        return;
                    }

                    this.checkInService.getRemainingBalance(this.pickup.Id).subscribe({
                        next: (remainingBalance) => {
                            this.remainingBalance = remainingBalance;
                            if (this.remainingBalance <= 0) {
                                this.redirectInProcess = true;
                                void this.router.navigate(['public/driver-checked-in']);
                                return;
                            }
                        }
                    });

                    this.checkInService.getSquareLocationIdForPickup(this.pickup.Id).subscribe({
                        next: (squareLocationId) => {
                            this.squareLocationId = squareLocationId;
                        }
                    });
                });
            });
        });
    }

    protected override handleSuccessfulPayment(token: string): void {
        this.checkInService.captureSquareOrderForPickup(this.checkIn.Id, token).subscribe({
            next: () => this.orderPaid(),
            error: () => {
                this.notificationsService.error('There was an error capturing the payment.');
                this.loading = false;
            }
        });
    }

    private orderPaid(): void {
        this.checkInPayPalService
            .sendPaymentSms(this.checkIn.Id, this.remainingBalance)
            .subscribe({
                next: () => {
                    this.notificationsService.success(
                        `${this.driver.FirstName} ${this.driver.LastName}, your payment has been successfully processed in the amount of $${this.remainingBalance.toFixed(2)} for pickup ${this.pickup.PickupNumber}.`
                    );
                    this.checkInService.checkedInDriver$.next(this.driver);
                    this.checkInService.driverCreatedCheckIn$.next(this.checkIn);
                    this.checkInService.checkedInPickup$.next(this.pickup);
                    void this.router.navigate(['public/driver-checked-in']);
                },
                error: () => {
                    this.notificationsService.error('There was an error sending SMS of successful payment.');
                    this.loading = false;
                }
            });
    }

    goBack(): void {
        this.location.back();
    }
}
