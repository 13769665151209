<div class="row">
    <div class="col-md-7">
        <h2>Fulfillment Report</h2>
    </div>
</div>
<mt-search-bar (searched)="search($event)"></mt-search-bar>
<mt-search-filter-daterange
    [startDate]="startDate"
    [endDate]="endDate"
    [entity]="entityName"
    (selectionChanged)="dateSelectionChanged($event)"
></mt-search-filter-daterange>
<div class="row">
    <h4 class="pull-left" style="padding-left: 15px !important">Average Waiting Time: {{ averageWaitingTime | hourfilter }}</h4>
    <button class="btn btn-success pull-right" style="padding-right: 15px !important" (click)="exportAsCSV()">
        Export To Excel&nbsp;&nbsp;<i class="fa fa-file-excel-o"></i>
    </button>
    <a href="javascript:void(0)" #tref></a>
</div>
<div class="table-responsive">
    <table class="table table-hover">
        <thead>
            <tr class="no-pointer">
                <th>
                    <b>Driver Name</b>
                </th>
                <th>
                    <b>Phone</b>
                </th>
                <th>
                    <b>Status</b>
                </th>
                <th>
                    <b>Check-In Date</b>
                </th>
                <th>
                    <b>Order Fulfilled Date</b>
                </th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let checkIn of checkIns">
                <tr>
                    <td class="ellipsis">
                        <span *ngIf="checkIn.CheckInUserId">
                            {{ checkIn.CheckInUser.FirstName + ' ' + checkIn.CheckInUser.LastName }}
                        </span>
                        <span *ngIf="checkIn.GuestUserId">
                            {{ checkIn.GuestUser.FirstName + ' ' + checkIn.GuestUser.LastName }}
                        </span>
                    </td>
                    <td class="ellipsis">
                        <span *ngIf="checkIn.CheckInUserId && checkIn.CheckInUser.Phone">
                            {{ checkIn.CheckInUser.Phone }}
                        </span>
                        <span *ngIf="checkIn.GuestUserId && checkIn.GuestUser.Phone">
                            {{ checkIn.GuestUser.Phone }}
                        </span>
                    </td>
                    <td class="ellipsis">
                        {{ checkIn.CheckInStatus.Name }}
                    </td>
                    <td class="ellipsis">
                        {{ checkIn.DateCreated | date: 'medium' }}
                    </td>
                    <td class="ellipsis">
                        <span *ngIf="checkIn.StatusId == 4">
                            {{ checkIn.DateModified | date: 'medium' }} - {{ checkIn.NotFulFilledReason.Name }}
                        </span>
                        <span *ngIf="checkIn.StatusId == 3">
                            {{ checkIn.DateFulfilled | date: 'medium' }}
                        </span>
                    </td>
                    <td class="ellipsis"></td>
                </tr>
                <tr class="no-pointer no-border">
                    <td class="ellipsis sub-item-header"></td>
                    <th class="sub-item-header">
                        <b>Customer Name</b>
                    </th>
                    <th class="sub-item-header">
                        <b>Pickup #</b>
                    </th>
                    <th class="sub-item-header">
                        <b>Order #</b>
                    </th>
                    <th class="sub-item-header">
                        <b>Appointment Time</b>
                    </th>
                    <th class="sub-item-header">
                        <b>Driver First Notified Time</b>
                    </th>
                    <th class="sub-item-header">
                        <b *ngIf="checkIn.CheckInDetails[0] && checkIn.CheckInDetails[0].ParsNumber">PARS Number</b>
                    </th>
                </tr>
                <tr class="no-border" *ngFor="let checkindetail of checkIn.CheckInDetails">
                    <td class="ellipsis sub-item"></td>
                    <td class="ellipsis sub-item">
                        {{ checkindetail.Customer.Name }}
                    </td>
                    <td class="ellipsis sub-item">
                        {{ checkIn.Pickup?.PickupNumber }}
                    </td>
                    <td class="ellipsis sub-item">
                        {{ checkindetail.OrderNo }}
                    </td>
                    <td class="ellipsis sub-item">
                        {{ checkindetail.Order?.PickUpDateTime ? (checkindetail.Order.PickUpDateTime | date: 'medium') : 'N/A' }}
                    </td>
                    <td class="ellipsis sub-item">
                        {{ checkindetail.DriverFirstNotifiedTime ? (checkindetail.DriverFirstNotifiedTime | date: 'medium') : 'N/A' }}
                    </td>
                    <td class="ellipsis sub-item">
                        {{ checkindetail.ParsNumber }}
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
<div *ngIf="noCheckInData()">
    <h3 style="text-align: center">No data to display.</h3>
</div>
