import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { IDelivery } from '../../model/interfaces/delivery';
import { IDeliveryEquipmentType } from '../../model/interfaces/delivery-equipment-type';
import { IDeliveryType } from '../../model/interfaces/delivery-type';
import { IDeliveryStatus } from '../../model/interfaces/delivery-status';
import { ITrailerType } from '../../model/interfaces/trailer-type';
import { ConfirmDeliveriesParams } from '../../model/interfaces/custom/confirm-deliveries-params';
import { GetDeliveriesForCalendarDTO } from '../../model/interfaces/custom/get-deliveries-for-calendar-dto';
import { SearchParams } from '@mt-ng2/common-classes';
import { IDeliveryForSearch } from '../../model/interfaces/custom/delivery-for-search';

export const emptyDelivery: IDelivery = {
    Archived: false,
    AtTemp: false,
    BookingNumber: null,
    CarrierId: 0,
    ChassisNumber: null,
    Coc: false,
    Commodity: null,
    CreatedById: 0,
    CustomerId: 0,
    DataFile: false,
    DateCreated: null,
    DeliveryDate: null,
    DeliveryNumber: null,
    DeliveryEquipmentTypeId: 0,
    Full: null,
    Hazmat: null,
    Id: 0,
    SealNumber: null,
    SecurityGateFee: null,
    ShippingLine: null,
    StatusId: 0,
    SupplierName: null,
    TrailerTypeId: 0,
    VesselDischargeDate: null,
    VesselName: null,
    VesselVoyage: null,
};

@Injectable()
export class DeliveryService extends BaseService<IDelivery> {
    constructor(public http: HttpClient) {
        super('/deliveries', http);
    }

    search(searchparameters: SearchParams): Observable<HttpResponse<IDeliveryForSearch[]>> {
        const params = this.getHttpParams(searchparameters);
        return this.http
            .get<IDeliveryForSearch[]>(`/deliveries/_search`, {
                observe: 'response',
                params: params,
            })
            .pipe(catchError((err, caught) => this.handleError(err as Response, caught)));
    }

    getEmptyDelivery(): IDelivery {
        return { ...emptyDelivery };
    }

    getSimpleDeliveryEquipmentTypes(): Observable<IDeliveryEquipmentType[]> {
        return this.http.get<IDeliveryEquipmentType[]>(`/deliveries/simple-delivery-equipment-types`);
    }

    getSimpleDeliveryTypes(): Observable<IDeliveryType[]> {
        return this.http.get<IDeliveryType[]>(`/deliveries/simple-delivery-types`);
    }

    getSimpleDeliveryStatuses(): Observable<IDeliveryStatus[]> {
        return this.http.get<IDeliveryStatus[]>(`/deliveries/simple-delivery-statuses`);
    }

    getSimpleTrailerTypes(): Observable<ITrailerType[]> {
        return this.http.get<ITrailerType[]>(`/deliveries/simple-trailer-types`);
    }

    downloadDeliveriesTemplate(): Observable<Blob> {
        return this.http.get(`/deliveries/template`, { responseType: 'blob' });
    }

    uploadDeliveriesTemplate(file: File): Observable<void> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        return this.http.post<void>(`/deliveries/template`, formData, { headers });
    }

    confirmDeliveries(deliveriesToConfirm: ConfirmDeliveriesParams[]): Observable<void> {
        return this.http.post<void>(`/deliveries/confirm`, deliveriesToConfirm);
    }

    updateGatePassNumber(delivery: IDelivery): Observable<void> {
        return this.http.put<void>(`/deliveries/${delivery.Id}/update-gate-pass-number`, delivery);
    }

    getConfirmedDeliveries(date: string): Observable<IDelivery[]> {
        return this.http.get<IDelivery[]>(`/deliveries/confirmed/${date}`);
    }

    getDeliveriesForCalendar(dto: GetDeliveriesForCalendarDTO): Observable<IDelivery[]> {
        return this.http.post<IDelivery[]>(`/deliveries/calendar/`, dto);
    }

    isOutsidePrePaymentTimeframe(deliveryId: number): Observable<boolean> {
        return this.http.put<boolean>(`/deliveries/${deliveryId}/outside-pre-payment-timeframe`, {
            responseType: 'text' as 'json',
        });
    }

    captureSquareOrder(deliveryId: number, squarePaymentToken: string): Observable<null> {
        return this.http.post<null>(`/deliveries/${deliveryId}/capture-square-order/${squarePaymentToken}`, null);
    }

    totalRemainingBalance(delivery: IDeliveryForSearch|IDelivery): number {
        const remainaingBalance = delivery.SecurityGateFee - this.totalPrePayment(delivery);
        return remainaingBalance > 0 
            ? remainaingBalance 
            : 0;
    }
    
    totalPrePayment(delivery: IDeliveryForSearch|IDelivery): number {
        if (!delivery.PickupPaymentDetails)
            return 0;
    
        return delivery.PickupPaymentDetails
            .filter(d => d.IsSuccessful)
            .reduce((sum, cur) => sum + cur.Amount, 0);
    }
}
