import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IDelivery } from '../../model/interfaces/delivery';
import { DeliveryService } from '../services/delivery.service';
import { BasePaymentComponent } from '../../common/components/base-payment.component';
import { SquarePaymentService } from '../../common/services/square-payment-service';

@Component({
    selector: 'app-delivery-pre-payment',
    templateUrl: './delivery-pre-payment.component.html',
    styleUrls: ['./delivery-pre-payment.component.css'],
})
export class DeliveryPrePaymentComponent extends BasePaymentComponent {
    delivery: IDelivery;
    deliveryId: number;
    twicFeeLabel = 'TWIC Fee';
    protected squareLocationId: string;
    protected remainingBalance: number;

    constructor(
        private deliveryService: DeliveryService,
        private route: ActivatedRoute,
        protected override squarePaymentService: SquarePaymentService,
        protected override notificationsService: NotificationsService,
        private router: Router,
    ) {
        super(squarePaymentService, notificationsService);
    }

    ngOnInit(): void {
        this.deliveryId = +this.route.snapshot.paramMap.get('deliveryId');
        this.deliveryService.getById(this.deliveryId).subscribe({
            next: (delivery) => {
                if (!delivery) {
                    this.redirectInProcess = true;
                    void this.router.navigate(['/deliveries']);
                    return;
                }

                this.delivery = delivery;
                this.remainingBalance = this.deliveryService.totalRemainingBalance(this.delivery);
                this.squareLocationId = this.delivery.Warehouse?.Office?.SquareLocationId;
                if (!this.squareLocationId) {
                    this.redirectInProcess = true;
                    this.notificationsService.error('The selected delivery does not have a office with a Square Location ID. Please contact support.');
                    void this.router.navigate(['/deliveries']);
                    return;
                }

                this.deliveryService.isOutsidePrePaymentTimeframe(this.deliveryId).subscribe({
                    next: (isOutsidePrePaymentTimeframe) => {
                        if (isOutsidePrePaymentTimeframe) {
                            this.redirectInProcess = true;
                            this.notificationsService.error('The ability to pre-pay for this delivery is outside the pre-payment time frame.');
                            void this.router.navigate(['/deliveries']);
                            return;
                        }
                    }
                });
            }
        });
    }

    protected handleSuccessfulPayment(token: string): void {
        this.deliveryService.captureSquareOrder(this.delivery.Id, token).subscribe({
            next: () => this.orderPaid(),
            error: () => {
                this.notificationsService.error('There was an error capturing the payment.');
                this.loading = false;
            }
        });
    }

    orderPaid(): void {
        this.notificationsService.success(`Your payment has been successfully processed in the amount of $${this.remainingBalance.toFixed(2)} for delivery ${this.delivery.DeliveryNumber}.`);
        void this.router.navigate(['/deliveries']);            
    }
}
