/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { EntityListConfig, SortDirection, EntityListColumn, IEntityListConfig, SelectTypes } from '@mt-ng2/entity-list-module';
import { formatDate, formatCurrency } from '@angular/common';
import { IPickupPaymentDetail } from '../../model/interfaces/pickup-payment-detail';

export class PickupPaymentDetailEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: function (detail: IPickupPaymentDetail): string {
                        return detail?.CheckIn?.Pickup?.PickupNumber ?? detail?.Pickup?.PickupNumber ?? detail?.Delivery?.DeliveryNumber; 
                    },
                    name: 'Pickup / Delivery Number',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'CheckIn.Pickup.PickupNumber ?? Pickup.PickupNumber ?? Delivery.DeliveryNumber',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (detail: IPickupPaymentDetail): string {
                        return formatDate(detail.DateCreated, 'medium', 'en-US');
                    },
                    name: 'Date Created',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'DateCreated',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (detail: IPickupPaymentDetail): string {
                        return formatCurrency(detail.Amount, 'en-US', '$');
                    },
                    name: 'Amount Charged',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'Amount',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (detail: IPickupPaymentDetail): string {
                        const checkInDriver = detail?.CheckIn?.CheckInUser;
                        if (checkInDriver?.Id > 0) 
                            return `${checkInDriver?.FirstName ?? ''} ${checkInDriver?.LastName ?? ''}`;

                        const pickupDriver = detail?.Pickup?.Driver;
                        if (pickupDriver?.Id > 0)
                            return `${pickupDriver?.FirstName ?? ''} ${pickupDriver?.LastName ?? ''}`;

                        return `N/A`;
                    },
                    name: 'Driver',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'CheckIn.CheckInUser.FirstName',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (detail: IPickupPaymentDetail): string {
                        return `${detail?.Pickup?.Carrier?.Name ?? detail?.Delivery?.Carrier?.Name}`;
                    },
                    name: 'Carrier',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'Pickup.Carrier.Name ?? Delivery.Carrier.Name',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (detail: IPickupPaymentDetail): string {
                        const order = detail?.Pickup?.Orders.find(order => order.Warehouse?.OfficeId > 0);
                        if (order) {
                            return `${order.Warehouse?.Office?.Title ?? 'N/A'}`
                        }
                        return detail?.Delivery?.Warehouse?.Office?.Title ?? 'N/A';
                    },
                    name: 'Office',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'Pickup.Orders.FirstOrDefault().Warehouse.Office.Title ?? Delivery.Warehouse.Office.Title',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (detail: IPickupPaymentDetail): string {
                        const order = detail?.Pickup?.Orders.find(order => order.Warehouse?.OfficeId > 0);
                        if (order) {
                            return `${order.Warehouse?.Office?.SquareLocationId ?? 'N/A'}`;
                        }
                        return detail?.Delivery?.Warehouse?.Office?.SquareLocationId ?? 'N/A';
                    },
                    name: 'Square Location ID',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'Pickup.Orders.FirstOrDefault().Warehouse.Office.SquareLocationId ?? Delivery.Warehouse.Office.SquareLocationId',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (detail: IPickupPaymentDetail): string {
                        return detail.IsSuccessful ? 'Successful' : 'Unsuccessful';
                    },
                    name: 'Payment Status',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'IsSuccessful',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (detail: IPickupPaymentDetail): string {
                        if (detail?.CheckIn?.CheckInUserId > 0) 
                            return `${detail?.CheckIn?.CheckInUser?.FirstName ?? ''} ${detail?.CheckIn?.CheckInUser?.LastName ?? ''}`;
                        
                        if (detail?.CreatedById > 0)
                            return `${detail?.CreatedBy?.FirstName ?? ''} ${detail?.CreatedBy?.LastName ?? ''}`;
                        
                        return 'N/A';
                    },
                    name: 'Paid By',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                        sortProperty: 'CreatedBy.FirstName',
                    },
                }),
            ],
        };
        listConfig.select = { type: SelectTypes.Single };
        super(listConfig);
    }
}
